import React from "react";
import {
  makeStyles,
  Typography,
  Container,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.white,
    position: "relative",
    height: "100vh",
  },
  aboutSection: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  logo1: {
    width: "40%",
    height: "auto",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    // marginLeft: "25%",
    textAlign: "center",
  },
  picBox: {
    // width: "40%",
    // height: "auto",
    // objectFit: "cover",
    // [theme.breakpoints.down("xs")]: {
    //     width: "60%",
    // },
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    // marginLeft: "25%",
    textAlign: "center",
  },
  list: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    // alignItems: "center",
    // color: theme.palette.primary,
  },
  logo: {
    width: "160px",
    height: "auto",
    objectFit: "cover",
    marginLeft: "-55%",
    justifyContent: "center",
    alignItems: "center",
    backgorund: "transparent",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },

  logo2: {
    width: "160px",
    height: "auto",
    objectFit: "cover",
    marginLeft: "-55%",
    justifyContent: "center",
    alignItems: "center",
    backgorund: "transparent",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "80%",
    },
  },
  eMoney: {
    marginLeft: "-5px",
    width: "160px",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "95%",
    },
  },
  eks: {
    marginLeft: "30px",
    width: "160px",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "100%",
    },
  },
  green: {
    marginLeft: "60px",

    width: "160px",
    // marginTop: "-50px",
    marginBottom: "30px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "95%",
    },
  },
  galaxy: {
    width: "160px",
    height: "auto",
    objectFit: "cover",
    marginLeft: "-55%",
    justifyContent: "center",
    alignItems: "center",
    backgorund: "transparent",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "100%",
    },
  },
}));

const Aboutus = (props) => {
  const classes = useStyles();
  return (
    <div id="about" className={classes.root}>
      <Container maxWidth="md">
        <Box mb={2} className={classes.picBox}>
          <img className={classes.logo1} src="images/c5m-dark.png" alt="" />
        </Box>
        <Box textAlign="justify">
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            Continent 5 Media Group (C5M) is a technology-driven ecosystem
            transforming how consumers and retailers connect. By integrating
            media, commerce, rewards, and entertainment, we leverage innovative
            platforms, creative content, and cutting-edge fintech solutions. Our
            mission is to Wow the World by creating unparalleled opportunities
            for engagement, growth, and innovation, empowering users and
            businesses to thrive across a connected digital landscape that spans
            all continents.
          </Typography>
          <br />
          {/* <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            If you are a Retailer, Company Brand, or Service Provider and want
            to learn how C5M can help you increase sales through global
            exposure, please click <Link>HERE</Link>.
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            If you are a musical performer, an author, or an artist and want to
            learn how C5M can help you increase sales through global exposure,
            please click <Link>HERE</Link>.
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            If you are at least 13 years old and want to earn cash or eMoney
            credit by participating in eksplode!’s Digital Ad Card (DAC) program
            or its Video Ad Contest, please click <Link>HERE</Link>.
          </Typography>
          <br /> */}
        </Box>
        <Box mb={2}>
          <Grid container justify="center" alignItems="center" spacing={-2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.list}>
                <ListItem>
                  <a href="http://galaxie.ir.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/ir.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://d2p.web.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/d2p_3.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.list}>
                <ListItem>
                  <a>
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/galaxie.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://titan.gaming.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/titanlogo.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://gallery.aurora.web.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/gallerylgo.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <List className={classes.list}>
                <ListItem>
                  <a href="http://galaxie.gr.s3-website-us-east-1.amazonaws.com">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.galaxy}
                        src="images/galaxy.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a>
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo2 && classes.eMoney}
                        src="images/logos/eMoney-logo.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://eksplode.web.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo2 && classes.eks}
                        src="images/eksplode.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="/greengenie/comingsoon">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo2 && classes.green}
                        src="images/greengenie.jpe"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
              </List>
            </Grid>
          </Grid>

          <ListItem>
            <ListItemText>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap:20
                }}
              >
                <Link to="/">
                  <Box textAlign="center" fontWeight="bold">
                    HOME
                  </Box>
                </Link>
                <Link to="/invest">
                  <Box textAlign="center" fontWeight="bold">
                    Join Our Mission, Invest in C5M
                  </Box>
                </Link>
              </div>
            </ListItemText>
          </ListItem>
        </Box>
      </Container>
    </div>
  );
};

export default Aboutus;
