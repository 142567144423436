import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import Contact from "../Contact/Contact";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(images/bg.png)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  products: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    alignItems: "center",
    background: "#FFFFFF",
    width: "100%",
    borderShadow: "28px 28px 50px rgba(13, 39, 80, 0.16)",
    borderRadius: "24px",
    height: "350px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      height: "270px",
      width: "100%",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  logo: {
    width: "50%",
    height: "auto",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  titleCenter: {
    textAlign: "center",
  },
}));
const Products = (props) => {
  const classes = useStyles();

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    console.log(width);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  });
  const productData = [
    {
      id: 1,
      title: "PUBLISHING",
      subtitle: "Decade 2 Publishing",
      logo: "images/d2plogo.png",
      link: "http://d2p.web.s3-website-us-east-1.amazonaws.com/",
      color: "#FB9C11",
    },
    {
      id: 2,
      title: "STREAMING SERVICE",
      subtitle: "Galaxie Music",
      logo: "images/galaxie.png",
      link: "/galaxiemusic/comingsoon",
      color: "#9433E3",
    },
    {
      id: 3,
      title: "MOBILE GAMING",
      subtitle: "Titan Mobile Gaming",
      logo: "images/titanlogo.png",
      link: "http://titan.gaming.s3-website-us-east-1.amazonaws.com/",
      color: "#F00032",
    },
    {
      id: 4,
      title: "MUSIC",
      subtitle: "Impact Records",
      logo: "images/ir.png",
      link: "http://galaxie.ir.s3-website-us-east-1.amazonaws.com/",
      color: "#F44CB9",
    },
    {
      id: 5,
      title: "DIGITAL ART",
      subtitle: "Gallery Aurora",
      logo: "images/gallerylgo.png",
      link: "http://gallery.aurora.web.s3-website-us-east-1.amazonaws.com/",
      color: "#B133BE",
    },
    {
      id: 6,
      title: "INTERACTIVITY",
      subtitle: "eksplode!",
      logo: "images/eksplode.png",
      link: "http://eksplode.web.s3-website-us-east-1.amazonaws.com/",
      color: "#EF6E78",
    },
    {
      id: 7,
      title: "VIDEO PRODUCTION",
      subtitle: "Counterstrike Entertainment",
      logo: "images/counterstrike.png",
      link: "/counterstrike/comingsoon",
      color: "#1562BD",
    },
    {
      id: 8,
      title: "GREEN INITIATIVES",
      subtitle: "Green Genie",
      logo: "images/greengenie.png",
      link: "/greengenie/comingsoon",
      color: "#00A859",
    },
    {
      id: 9,
      title: "PAYMENT APP",
      subtitle: "eMoney",
      logo: "images/eMoneyGold.png",
      link: "http://eksplode.code.s3-website-us-east-1.amazonaws.com",
      color: "#fcd739",
    },
    {
      id: 10,
      title: "BLOG",
      subtitle: "LawWiz",
      logo: "images/lawwiz.png",
      link: "/",
      color: "#fcd739",
    },
  ];

  return (
    <div className={classes.root}>
      <Box sx={{ padding: "4rem" }}>
        <Grid container spacing={4}>
          {productData.map((product) => (
            <Grid key={product.id} item xs={12} sm={6} md={6}>
              <a target="_blank" href={product.link} rel="noopener noreferrer">
                <div
                  className={clsx([classes.products, classes.titleCenter])}
                  style={{ borderColor: product.color }}
                >
                  <Typography
                    variant="h4"
                    style={{ color: product.color, fontWeight: "600" }}
                  >
                    {product.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ fontStyle: "italic", color: "black" }}
                  >
                    {product.subtitle}
                  </Typography>
                  <div className={classes.container}>
                    <img
                      className={classes.logo}
                      src={product.logo}
                      alt={product.subtitle}
                    />
                  </div>
                </div>
              </a>
            </Grid>
          ))}
        </Grid>
        <div style={{ marginTop: "4rem" }}>
          <Contact />
        </div>
      </Box>
    </div>
  );
};

export default Products;
