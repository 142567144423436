import React from "react";
import {
  makeStyles,
  Typography,
  Container,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { Link } from "react-router-dom";
// import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.white,
    position: "relative",
    height: "100vh",
  },
  aboutSection: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  logo1: {
    width: "40%",
    height: "auto",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    // marginLeft: "25%",
    textAlign: "center",
  },
  picBox: {
    // width: "40%",
    // height: "auto",
    // objectFit: "cover",
    // [theme.breakpoints.down("xs")]: {
    //     width: "60%",
    // },
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    // marginLeft: "25%",
    textAlign: "center",
  },
  list: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    // alignItems: "center",
    // color: theme.palette.primary,
  },
  logo: {
    width: "160px",
    height: "auto",
    objectFit: "cover",
    marginLeft: "-55%",
    justifyContent: "center",
    alignItems: "center",
    backgorund: "transparent",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },

  logo2: {
    width: "160px",
    height: "auto",
    objectFit: "cover",
    marginLeft: "-55%",
    justifyContent: "center",
    alignItems: "center",
    backgorund: "transparent",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "80%",
    },
  },
  eMoney: {
    marginLeft: "-5px",
    width: "160px",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "95%",
    },
  },
  eks: {
    marginLeft: "30px",
    width: "160px",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "100%",
    },
  },
  green: {
    marginLeft: "60px",

    width: "160px",
    // marginTop: "-50px",
    marginBottom: "30px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "95%",
    },
  },
  galaxy: {
    width: "160px",
    height: "auto",
    objectFit: "cover",
    marginLeft: "-55%",
    justifyContent: "center",
    alignItems: "center",
    backgorund: "transparent",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "100%",
    },
  },
}));

const SafeContract = (props) => {
  const classes = useStyles();
  return (
    <div id="SafeContract" className={classes.root}>
      <Container maxWidth="md">
        <Box mb={2} className={classes.picBox}>
          <img className={classes.logo1} src="images/c5m-dark.png" alt="" />
          <h3>C5M SAFE (Simple Agreement for Future Equity)</h3>
          <p>Last revised January 16, 2025</p>
        </Box>
        <Box textAlign="justify">
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            This Simple Agreement for Future Equity ("SAFE") is entered into as
            of [Date], by and between Continent 5 Media Group, Inc. ("C5M" or
            the "Company"), a [State of Incorporation] corporation, and (the
            "Investor").
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ol style={{ marginLeft: 20 }}>
              <li>
                Investment Amount The Investor agrees to invest
                $_________________in the Company (the "Purchase Amount") in
                exchange for the rights described in this SAFE.
              </li>
              <li>
                Conversion Terms
                <ol style={{ marginLeft: 20 }}>
                  <li>
                    Valuation Cap: $10,000,000.00
                    <ul style={{ marginLeft: 40 }}>
                      <li>
                        The Investor’s SAFE will convert into equity at the next
                        equity financing round, subject to the valuation cap of
                        $10,000,000.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Discount Rate: 20%
                    <ul style={{ marginLeft: 40 }}>
                      <li>
                        The Investor will receive a 20% discount on the price
                        per share determined in the next equity financing round.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Conversion Event:
                    <ul style={{ marginLeft: 40 }}>
                      <li>
                        The SAFE will convert into equity upon the occurrence of
                        either: a) A Qualified Equity Financing ("QEF"), defined
                        as the Company’s issuance of common stock in a financing
                        event. b) A Liquidity Event, including a merger,
                        acquisition, or sale of substantially all of the
                        Company’s assets.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>

              <li>
                Proceeds on Liquidity Event If there is a Liquidity Event before
                this SAFE converts, the Investor will receive a payment equal to
                the greater of: a) The Purchase Amount, or b) The amount payable
                had the SAFE converted immediately prior to the Liquidity Event.
              </li>
              <li>
                Dividend Policy The Company’s dividend policy is as follows:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    No dividends will be issued in the first year of operation
                  </li>
                  <li>
                    Beginning in the second year, 10% of distributable profits
                    will be distributed as dividends proportionally to
                    shareholders based on their ownership percentages.
                  </li>
                </ul>
              </li>
              <li>
                Company Representations The Company represents and warrants:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    It is duly incorporated and in good standing under the laws
                    of Delaware.
                  </li>
                  <li>
                    This SAFE has been duly authorized, executed, and delivered
                    by the Company.
                  </li>
                  <li>
                    The Company has only issued common shares and does not have
                    preferred shares.
                  </li>
                </ul>
              </li>
              <li>
                Investor Representations The Investor represents and warrants:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    They have full legal capacity and authority to execute and
                    deliver this SAFE.
                  </li>
                  <li>
                    They are investing solely for their own account and not with
                    a view to distribute.
                  </li>
                </ul>
              </li>
              <li>
                No Voting or Control Rights: The SAFE does not provide the
                Investor with any voting rights, board seats, or control over
                Company operations until it converts into equity
              </li>
              <li>
                Amendments and Waivers: Any amendment or waiver of this SAFE
                must be in writing and signed by both the Company and the
                Investor
              </li>
              <li>
                Governing Law This SAFE will be governed by and construed in
                accordance with the laws of the State of [State].
              </li>
              <li>
                10. Miscellaneous
                <ul>
                  <li>
                    The Investor acknowledges the speculative nature of this
                    investment and that there is no guarantee of a return on the
                    Purchase Amount.
                  </li>
                  <li>
                    This SAFE constitutes the entire agreement between the
                    parties concerning its subject matter.
                  </li>
                </ul>
              </li>
            </ol>
          </Typography>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            IN WITNESS WHEREOF, the parties have executed this SAFE as of the
            date first written above.
          </Typography>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            Date: ________________
          </Typography>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            {" "}
            Continent 5 Media Group, Inc. By: ____________________________
          </Typography>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            Name: Michael Schulze
          </Typography>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            Title: CEO
          </Typography>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            Date: ________________
          </Typography>
          <br />
          <a href="https://c5m-assets.s3.amazonaws.com/1739222131075.pdf" download>
            <b>Download Safe Contract PDF</b>
          </a>
        </Box>
        <Box mb={2}>
          <Grid container justify="center" alignItems="center" spacing={-2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.list}>
                <ListItem>
                  <a href="http://galaxie.ir.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/ir.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://d2p.web.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/d2p_3.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.list}>
                <ListItem>
                  <a>
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/galaxie.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://titan.gaming.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/titanlogo.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://gallery.aurora.web.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/gallerylgo.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <List className={classes.list}>
                <ListItem>
                  <a href="http://galaxie.gr.s3-website-us-east-1.amazonaws.com">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.galaxy}
                        src="images/galaxy.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a>
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo2 && classes.eMoney}
                        src="images/eMoney.jpe"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://eksplode.web.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo2 && classes.eks}
                        src="images/eksplode.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="/greengenie/comingsoon">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo2 && classes.green}
                        src="images/greengenie.jpe"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
              </List>
            </Grid>
          </Grid>

          <ListItem>
            <ListItemText>
              <a href="/">
                <Box textAlign="center" fontWeight="bold">
                  HOME
                </Box>
              </a>
            </ListItemText>
          </ListItem>
        </Box>
      </Container>
    </div>
  );
};

export default SafeContract;
