import React from "react";
import { makeStyles, Typography, Container, Box } from "@material-ui/core";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.white,
    position: "relative",
    height: "100vh",
  },
  title: {
    // width: "40%",
    // height: "auto",
    // objectFit: "cover",
    // [theme.breakpoints.down("xs")]: {
    //     width: "60%",
    // },
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    // marginLeft: "25%",
    textAlign: "center",
  },
  footertitle: {
    // },
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
  },
}));

const PrivacyPolicy = (props) => {
  const classes = useStyles();
  return (
    <div id="about" className={classes.root}>
      <Container maxWidth="md">
        <Box mb={2} className={classes.title}>
          <h1>Continent 5 Media Group, Inc.</h1>
          <h3>PRIVACY POLICY</h3>
        </Box>
        <Box textAlign="justify">
          <Typography color="primaryText" variant="body1">
            Continent 5 Media Group, Inc. complies with all applicable privacy
            laws. Our Privacy Policy is intended to explain how we collect and
            use personal data when you use our websites and to alert you to the
            rights you may have to control how the personal data is gathered and
            used.
          </Typography>
          <br />
          <Typography color="primaryText" variant="body1">
            “Continent 5 Media,” “C5M,” “we,” “our,” and “us” refer to Continent
            5 Media Group, Inc., and “you” and “yours” refer to visitors and
            users of our websites.
          </Typography>
          <br />
          <Typography color="primaryText" variant="body1">
            1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "bold" }}>Websites.</span> Our Terms of
            Use and Privacy Policy apply to the following websites:
          </Typography>
          <br />
          {[
            {
              title: "www.AskTheLawGuru.com",
              url: "https://www.AskTheLawGuru.com",
            },
            {
              title: "www.BigBangRecords.ca",
              url: "https://www.BigBangRecords.ca",
            },
            { title: "www.C5M.world", url: "https://www.C5M.world" },
            {
              title: "www.Continent5Media.com",
              url: "https://www.Continent5Media.com",
            },
            {
              title: "www.Continent5MediaGroup.com",
              url: "https://www.Continent5MediaGroup.com",
            },
            {
              title: "www.CounterstrikeEntertainment.com",
              url: "/counterstrike/comingsoon",
            },
            {
              title: "www.Decade2Publishing.com",
              url: "http://d2p.web.s3-website-us-east-1.amazonaws.com/",
            },
            {
              title: "www.eksplode.com",
              url: "http://eksplode.web.s3-website-us-east-1.amazonaws.com/",
            },
            {
              title: "www.FederalLawWiz.com",
              url: "https://www.FederalLawWiz.com",
            },
            {
              title: "www.GalaxieRecordsUSA.com",
              url: "www.GalaxieRecordsUSA.com",
            },
            {
              title: "www.GalaxieMusic.com",
              url: "/galaxiemusic/comingsoon",
            },
            {
              title: "www.GalleryAurora.com",
              url: "http://gallery.aurora.web.s3-website-us-east-1.amazonaws.com/",
            },
            {
              title: "www.ImpactRecords.ca",
              url: "http://galaxie.ir.s3-website-us-east-1.amazonaws.com/",
            },
            {
              title: "www.KleverBook.com",
              url: "http://d2p.web.s3-website-us-east-1.amazonaws.com/kleverbooks",
            },
            { title: "www.Mind4Law.com", url: "https://www.Mind4Law.com" },
            {
              title: "www.OperationVegasIce.com",
              url: "https://www.OperationVegasIce.com",
            },
            {
              title: "www.PaperJumsuit.com",
              url: "https://www.PaperJumsuit.com",
            },
            {
              title: "www.TitanMobileGaming.com",
              url: "http://titan.gaming.s3-website-us-east-1.amazonaws.com/",
            },
          ].map((site, index) => (
            <Typography key={index} color="primaryText" variant="body1">
              <Link style={{ lineHeight: "40px" }} href={site.url}>
                {site.title}
              </Link>
              <br />
            </Typography>
          ))}
          <Typography color="primaryText" variant="body1">
            The owner of these websites is Continent 5 Media Group. To contact
            us, please send an email to
            <br />
            <Link href="mailto:Admin@continent5media.com">
              Admin@continent5media.com
            </Link>{" "}
            or <Link href="mailto:Admin@c5m.world">Admin@c5m.world.</Link>
          </Typography>
          <Typography color="primaryText" variant="body1">
            2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "bold" }}>Most Recent Update.</span> Our
            Terms of Use and Privacy Policy were last updated on February 19,
            2024. Please return to our Terms of Use and Privacy Policy to
            determine if we have made any changes since your last visit.
          </Typography>
          <Typography color="primaryText" variant="body1">
            3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "bold" }}>
              Consent to Use of Cookies.
            </span>{" "}
            Like virtually all websites, our websites use “cookies” that are
            automatically installed on your computer when you visit our
            websites. Cookies are small text files stored on your computer and
            can be recognized when you return to our websites. Some cookies are
            installed by the service providers who host and maintain our
            websites or advertisers. The cookies will collect some of your
            personal data. On the landing page, you will be allowed to consent
            (or “opt-in”) to the use of cookies. If you do not opt-in, or if you
            opt-in but later remove or turn off the cookies, the website(s) will
            not be fully functional.
          </Typography>
          <Typography color="primaryText" variant="body1">
            4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "bold" }}>
              Consent to Terms of Use and Privacy Policy.
            </span>{" "}
            When you purchase products or services through C5M’s websites, you
            will be asked to confirm that you agree to be bound by our Terms of
            Use and our Privacy Policy. If you disagree, you will not be able to
            complete the purchase.
          </Typography>
          <Typography color="primaryText" variant="body1">
            5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "bold" }}>
              Consent Based on Your Use of Our Websites.
            </span>{" "}
            Even if you do not consent to be bound by our Terms of Use and
            Privacy Policy at the time of purchase, please be aware that we will
            rely on your continued use of our websites as confirmation of your
            consent to be bound if applicable law permits us to do so. Your
            consent applies to our Terms of Use and Privacy Policy, which may be
            updated occasionally. Please do not visit or use our websites if you
            do not want to be bound.
          </Typography>
          <Typography color="primaryText" variant="body1">
            6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "bold" }}>Personal Data.</span> Here is a
            summary of how we acquire and use visitors’ personal data on our
            websites.
            <br />
            <span style={{ padding: "2.5rem" }}>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;We collect some personal data through
              cookies when you register as a user of our website(s) and when you
              purchase products and services through our websites.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;The personal data we collect and
              process may include your name, IP address, geolocation
              information, passwords, email addresses, mailing addresses,
              telephone numbers, professional status, transaction, and traffic
              data.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (c)&nbsp;&nbsp;&nbsp;&nbsp;We do not currently collect sensitive
              personal information such as Social Security numbers, age,
              driver’s license numbers, usernames, or information on race,
              ethnicity, religious or philosophical beliefs. Please monitor our
              Terms of Use and Privacy Policy for any changes in the future.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (d)&nbsp;&nbsp;&nbsp;&nbsp;We act as the controller of the
              personal data we collect, and we may enter into written contracts
              with service providers who assist us in processing personal data.
            </span>
          </Typography>
          <Typography color="primaryText" variant="body1">
            7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "bold" }}>
              What We Do with Your Personal Data.
            </span>{" "}
            As a general rule, we use your personal data only for the purposes
            described in this Privacy Policy and only to the extent that our use
            of your personal data is reasonable and relevant to these purposes.
          </Typography>
          <br />
          <Typography color="primaryText" variant="body1">
            <span style={{ padding: "2.5rem" }}>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;We use your personal data only for the
              following purposes: (a) Monitoring and maintaining the
              functionality of our websites to make sure it is operating
              correctly; (b) Communicating with our customers and websites
              visitors and users; (c) Market research and marketing of the
              products and services of C5M and its subsidiaries; and (iv)
              Detecting any use of our websites that violates our Terms of Use.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;We do not keep personal data any longer
              than is necessary for business purposes, and we delete personal
              data when it is no longer required.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (c)&nbsp;&nbsp;&nbsp;&nbsp;We use our commercially reasonable best
              efforts to maintain the security of the personal data that we
              collect and use.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (d)&nbsp;&nbsp;&nbsp;&nbsp;We do not buy or sell personal data,
              and we do not share personal data except with third parties who
              agree to comply with applicable privacy laws.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (e)&nbsp;&nbsp;&nbsp;&nbsp;We do not use automated decision-making
              technology such as “profiling.”
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (f)&nbsp;&nbsp;&nbsp;&nbsp;We use your data to communicate with
              you on our websites and by email to inform you of the information,
              products, and services available through C5M, to provide customer
              service, and to conduct surveys.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (g)&nbsp;&nbsp;&nbsp;&nbsp;We reserve the right to retain and
              disclose personal data (i) when we are legally required to do so
              by law or court order, (ii) when it is necessary to do so to
              enforce the Terms of Use, and/or (iii) to protect the rights,
              safety or security of C5M, our advertisers and affiliates, and our
              employees, contractors, and vendors.
            </span>
          </Typography>
          <Typography color="primaryText" variant="body1">
            8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "bold" }}>
              Your Privacy Rights.
            </span>{" "}
            Depending on what laws apply to your use of our websites and our
            collection of personal data, which may change from time to time in
            the future, you may have the following rights regarding your
            personal data, and you may exercise any of the rights you have by
            contacting our Data Protection Officer at{" "}
            <Link href="mailto:Admin@Continent5Media.com">
              Admin@Continent5Media.com
            </Link>{" "}
            or{" "}
            <Link href="mailto:Admin@C5M.world.com">Admin@C5M.world.com</Link>:
            <br />
            <span style={{ padding: "2.5rem" }}>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;The right to withdraw your consent to
              the use of cookies.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;The right to withdraw your consent to
              the collection and processing of your personal data.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (c)&nbsp;&nbsp;&nbsp;&nbsp;The right to request a copy of your
              personal data that we have collected.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (d)&nbsp;&nbsp;&nbsp;&nbsp;The right to update, correct, change,
              block, or erase your personal data.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (e)&nbsp;&nbsp;&nbsp;&nbsp;The right to transfer some or all of
              your personal data to another data controller.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (f)&nbsp;&nbsp;&nbsp;&nbsp;The right to limit the use and
              disclosure of sensitive personal information.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (g)&nbsp;&nbsp;&nbsp;&nbsp;The right to opt out of receiving
              marketing communications from us by email.
            </span>
          </Typography>
          <Typography color="primaryText" variant="body1">
            9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "bold" }}>
              Privacy Policy for Minors.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;Some of our websites offer programs and
              content that are intended for use by children but only with the
              consent and under the supervision of their parents or guardians.
              However, access to the content allows for purchases of products
              and services, and only users 18 years of age or older are
              permitted to make purchases. We rely on these users to decide
              whether our content is appropriate for any minor with whom the
              content will be shared.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;We do not knowingly collect personal
              data from minors.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (c)&nbsp;&nbsp;&nbsp;&nbsp;Individuals under the age of thirteen
              must ask their parent or guardian for permission to use our
              websites.
            </span>
          </Typography>
          <Typography color="primaryText" variant="body1">
            10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "bold" }}>Scope of Privacy Policy.</span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;Our Privacy Policy applies only to
              personal data that we collect when you visit and use our websites
              and not to information that we collect through our offline
              business.
            </span>
            <br />
            <span style={{ padding: "2.5rem" }}>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;Our Privacy Policy does not apply to
              other websites that may be linked to our websites, including
              websites operated by advertisers and other third parties. If you
              leave our website(s) and go to another, please be careful to read
              the Terms of Use and Privacy Policy posted on the other website.
            </span>
          </Typography>
          <Typography color="primaryText" variant="body1">
            11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "bold" }}>Contact.</span> If you have
            questions about our Terms of Use and Privacy Policy, don't hesitate
            to contact the Data Privacy Officer for C5M at{" "}
            <Link href="mailto:Admin@Continent5Media.com">
              Admin@Continent5Media.com
            </Link>{" "}
            or{" "}
            <Link href="mailto:Admin@C5M.world.com">Admin@C5M.world.com</Link>:
          </Typography>
          <br />
          <br />
          <br />
          <Typography
            className={classes.footertitle}
            color="primaryText"
            variant="body1"
          >
            – End of Privacy Policy – <br />
            Last Updated February 19, 2024
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
