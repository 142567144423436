import React from "react";
import {
  makeStyles,
  Typography,
  Container,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { Link } from "react-router-dom";
// import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.white,
    position: "relative",
    height: "100vh",
  },
  aboutSection: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  logo1: {
    width: "40%",
    height: "auto",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    // marginLeft: "25%",
    textAlign: "center",
  },
  picBox: {
    // width: "40%",
    // height: "auto",
    // objectFit: "cover",
    // [theme.breakpoints.down("xs")]: {
    //     width: "60%",
    // },
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    // marginLeft: "25%",
    textAlign: "center",
  },
  list: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    // alignItems: "center",
    // color: theme.palette.primary,
  },
  logo: {
    width: "160px",
    height: "auto",
    objectFit: "cover",
    marginLeft: "-55%",
    justifyContent: "center",
    alignItems: "center",
    backgorund: "transparent",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },

  logo2: {
    width: "160px",
    height: "auto",
    objectFit: "cover",
    marginLeft: "-55%",
    justifyContent: "center",
    alignItems: "center",
    backgorund: "transparent",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "80%",
    },
  },
  eMoney: {
    marginLeft: "-5px",
    width: "160px",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "95%",
    },
  },
  eks: {
    marginLeft: "30px",
    width: "160px",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "100%",
    },
  },
  green: {
    marginLeft: "60px",

    width: "160px",
    // marginTop: "-50px",
    marginBottom: "30px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "95%",
    },
  },
  galaxy: {
    width: "160px",
    height: "auto",
    objectFit: "cover",
    marginLeft: "-55%",
    justifyContent: "center",
    alignItems: "center",
    backgorund: "transparent",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "100%",
    },
  },
}));

const LongInvestPitch = (props) => {
  const classes = useStyles();
  return (
    <div id="LongInvestPitch" className={classes.root}>
      <Container maxWidth="md">
        <Box mb={2} className={classes.picBox}>
          <img className={classes.logo1} src="images/c5m-dark.png" alt="" />
          <h3>Continent 5 Media Group, Inc. (C5M) Investor Pitch</h3>
          <p>Last revised January 16, 2025</p>
        </Box>
        <Box textAlign="justify">
          <h4>Executive Summary</h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            C5M is a pre-revenue startup created to lead the Digital
            Renaissance, a transformative era redefining how people create,
            share, and interact with digital content. Through innovative
            platforms and interconnected systems, C5M empowers creators, bridges
            accessibility gaps, and drives engagement in ways that align with
            this cultural and technological rebirth. industries.
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            With divisions spanning music, publishing, gaming, advertising, and
            streaming, C5M is uniquely positioned to capture a share of the $650
            billion global digital advertising market and capitalize on the
            growing online marketplace sector, which reached $8.8 trillion in
            2024 and continues to grow.
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            C5M functions more than just as a digital media company—it
            integrates the dynamic features of an online marketplace by
            connecting consumers, creators, and brands. From facilitating online
            purchases to earning retailer commissions, C5M empowers transactions
            and value exchange across its platforms. C5M’s eMoney Network
            further enhances this by enabling seamless user purchases, creating
            a self-sustaining ecosystem.
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            GreenGenie, a proprietary program, amplifies this mission by
            centralizing financial activity within C5M’s ecosystem. GreenGenie
            empowers participants to reinvest earnings, purchase ads, and fund
            additional platform activities, reducing transaction fees and
            increasing retained capital. This closed- loop approach enhances
            profitability and strengthens user engagement and loyalty,
            positioning GreenGenie as a pivotal asset within C5M’s ecosystem.
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            With 70% of our platforms and software developed, we seek to
            increase our operating capital by offering 5% ownership of C5M by
            selling 500,000 common shares at $1.00 per share. This funding will
            provide a two-year sustainable runway, enabling robust product
            development, marketing expansion, and operational scaling to achieve
            rapid user acquisition and revenue growth.
          </Typography>
          <br />
          <h4>The Problems</h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 20 }}>
              <li>
                Traditional Advertising Challenges: Consumers are overwhelmed by
                ads and increasingly use ad blockers, making it difficult for
                brands to connect with their audiences.
              </li>
              <li>
                Limited Access to Payment Systems: Many younger users, including
                those under 18, lack access to traditional banking and payment
                systems, restricting their ability to engage in digital commerce
                independently.
              </li>
              <li>
                Challenges in Digital Publishing: The digital publishing era
                faces critical challenges. Traditional eBook advertising models
                often interrupt the natural flow of content, leading to user
                dissatisfaction and low engagement. Existing systems fail to
                balance free access to content 2 with sustainable revenue models
                for publishers and authors. Additionally, inefficient cataloging
                systems diminish user experience, limiting interaction with
                digital books.
              </li>
            </ul>
          </Typography>
          <br />
          <h4>The Solutions</h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            C5M creates value-driven, engaging advertising experiences through
            platforms like:
            <ul style={{ marginLeft: 20 }}>
              <Box>
                <a href="http://d2p.web.s3-website-us-east-1.amazonaws.com/">
                  <Box textAlign="center" fontWeight="bold">
                    <img
                      className={classes.logo}
                      src="images/d2p_3.png"
                      alt=""
                    />
                  </Box>
                </a>
                <li>
                  decade 2 publishing (d2p) and its KleverBookTM App: The
                  KleverBookTM App addresses critical challenges in the digital
                  publishing era by integrating non-intrusive and interactive
                  advertising within eBooks. Traditional models struggle with
                  balancing free content access and sustainable revenue for
                  publishers and authors. The KleverBook revolutionizes this
                  space by ensuring profitability through seamlessly integrated
                  ads that enhance rather than disrupt the reading experience.
                  By improving content cataloging for more straightforward
                  navigation and creating a more engaging and interactive user
                  experience, KleverBook resolves issues of limited user
                  engagement and inefficient monetization while promoting free
                  access to a diverse library of digital books.
                </li>
              </Box>
              <Box>
                <a href="http://eksplode.web.s3-website-us-east-1.amazonaws.com/">
                  <Box textAlign="center" fontWeight="bold">
                    <img
                      className={classes.logo}
                      src="images/eksplode.png"
                      alt=""
                    />
                  </Box>
                </a>
                <li>
                  eksplode! Interactive: Innovative ad formats like eksplode!’s
                  DAC and VAC programs use p2p technology and entertainment to
                  bring customers and retailers together in fun and rewarding
                  ways.
                  <ul style={{ marginLeft: 40 }}>
                    <li>
                      DAC Program: The Digital Ad Card (DAC) program empowers
                      eksplode! Members to earn commissions by promoting
                      products to their contacts through personalized digital
                      cards. Members select products from a Retailers Catalog,
                      send customizable DACs to their contacts, and earn
                      commissions on purchases made through their unique
                      eksplode! Code (QR code). This system not only drives
                      sales but also incentivizes user engagement with brands.
                      Commission fees vary by product and retailer, offering
                      members competitive payouts based on the value of items
                      sold.
                    </li>
                    <li>
                      Video Ad Contest (VAC): Participants in the eksplode! VAC
                      receives $1.00 for each video accepted into the contest.
                      By securing intellectual property and concept rights for
                      every video, C5M builds a library of valuable content.
                      Winning videos, proven brilliant in concept, can then be
                      sold to the advertising world for $10,000 or more, with
                      the potential for significantly higher sales depending on
                      concept brilliance and advertiser demand. This approach
                      transforms a nominal initial investment into premium
                      advertising content, significantly enhancing the value of
                      C5M’s ecosystem by monetizing creativity and innovation.
                    </li>
                  </ul>
                </li>
              </Box>
              <Box>
                <a>
                  <Box textAlign="center" fontWeight="bold">
                    <img
                      className={classes.logo}
                      src="images/eMoney.jpe"
                      alt=""
                    />
                  </Box>
                </a>
                <li>
                  eMoney Network: A proprietary payment system facilitating
                  transactions and rewards across the C5M ecosystem. Designed
                  for younger users without bank accounts, participants are
                  issued a QR code linked to C5M’s main account. When users earn
                  money on our platform, they can use their QR code to purchase
                  online and in participating retail stores. This innovative
                  system reduces transaction costs, fosters loyalty, and retains
                  users while solving accessibility challenges for underbanked
                  audiences.
                </li>
              </Box>
              <Box>
                <a href="/greengenie/comingsoon">
                  <Box textAlign="center" fontWeight="bold">
                    <img
                      className={classes.logo}
                      src="images/greengenie.jpe"
                      alt=""
                    />
                  </Box>
                </a>
                <li>
                  GreenGenie: A proprietary program enabling participants to
                  reinvest earnings within C5M’s ecosystem. Users can fund
                  advertising campaigns, access premium features, and purchase
                  digital goods, all while reducing reliance on external payment
                  processors. GreenGenie retains funds in the ecosystem,
                  minimizing transaction costs and providing C5M with additional
                  financial liquidity.
                </li>
              </Box>
              <Box>
                <a href="http://titan.gaming.s3-website-us-east-1.amazonaws.com/">
                  <Box textAlign="center" fontWeight="bold">
                    <img
                      className={classes.logo}
                      src="images/titanlogo.png"
                      alt=""
                    />
                  </Box>
                </a>
                <li>
                  Titan Mobile Gaming: Titan Mobile Gaming will be a significant
                  revenue driver for C5M, tapping into the $200 billion global
                  gaming industry. The platform integrates ads into gameplay and
                  offers cash rewards to top players, gamifying advertising to
                  foster user engagement and long-term retention. Titan also
                  monetizes through affiliate marketing, in-app purchases, and
                  premium tournaments, providing multiple revenue streams while
                  delivering value to users and advertisers.
                </li>
              </Box>
              <Box>
                <a href="http://galaxie.ir.s3-website-us-east-1.amazonaws.com/">
                  <Box textAlign="center" fontWeight="bold">
                    <img className={classes.logo} src="images/ir.png" alt="" />
                  </Box>
                </a>
                <li>
                  Impact Records (IR): Impact Records is our music division,
                  dedicated to providing artists a platform to showcase and sell
                  their digital music. IR also curates a catalog of royalty-free
                  music for eksplode!'s Video Ad Contest (VAC) program and C5M's
                  streaming music service, Galaxie Music, enabling seamless
                  integration of music across our platforms. This model offers
                  artists global exposure and revenue opportunities, while C5M
                  earns a commission on sales made through these platforms,
                  contributing to the company’s broader ecosystem.
                </li>
              </Box>
              <Box>
                <a>
                  <Box textAlign="center" fontWeight="bold">
                    <img
                      className={classes.logo}
                      src="images/galaxie.png"
                      alt=""
                    />
                  </Box>
                </a>
                <li>
                  Galaxie Music (GM): A streaming platform that integrates music
                  into C5M’s ecosystem, providing free exposure for artists
                  while powering creative advertising solutions. Its Galaxie
                  Music Library (GML) offers royalty-free tracks for use in
                  eksplode!'s Video Ad Contests (VAC) and other campaigns,
                  reducing advertising costs and enhancing engagement.
                </li>
              </Box>
              <Box>
                <a href="http://gallery.aurora.web.s3-website-us-east-1.amazonaws.com/">
                  <Box textAlign="center" fontWeight="bold">
                    <img
                      className={classes.logo}
                      src="images/gallerylgo.png"
                      alt=""
                    />
                  </Box>
                </a>
                <li>
                  Gallery Aurora (GA): This platform hosts digital art,
                  including NFTs, and integrates with unique use cases like the
                  book Paper Jumpsuit. QR codes or hyperlinks in the book direct
                  readers to C5M’s homepage, where they can discover Gallery
                  Aurora. Readers can view digital images associated with the
                  book, learn about GA’s offerings, and explore other C5M
                  platforms. This approach saves on printing costs for authors
                  while driving traffic to C5M’s ecosystem, enhancing user
                  experience and platform exposure.
                </li>
              </Box>
            </ul>
          </Typography>
          <br />
          <h4>Market Opportunity</h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            C5M stands out from competitors by combining innovative platforms,
            diverse revenue streams, and accessibility-focused solutions. Here’s
            how C5M differentiates across industries:
            <ul style={{ marginLeft: 20 }}>
              <li>
                Integrated Ecosystem of Platforms: Unlike competitors focused on
                single niches, C5M offers a unified ecosystem. Platforms like
                KleverBook™, Titan Mobile Gaming, Galaxie Music, Gallery Aurora,
                and eksplode! drive user engagement across multiple touchpoints,
                maximizing lifetime value.
              </li>
              <li>
                eMoney Network: Unlike payment systems that require banking
                links or parental oversight (e.g., Cash App, PayPal), the eMoney
                Network eliminates these barriers. Users earn and spend
                seamlessly within C5M’s ecosystem using QR codes, fostering
                loyalty and accessibility.
              </li>
              <li>
                Innovative Advertising Models (eksplode!): eksplode!’s DAC and
                VAC programs personalize advertising, creating rewarding user
                experiences. Brands benefit from measurable ROI, while users
                earn rewards for participation.
              </li>
              <li>
                KleverBook™ for Non-Intrusive Advertising in eBooks: Unlike
                subscription-only platforms like Kindle, KleverBook™ offers
                ad-supported free access to digital books. This model balances
                reader satisfaction and revenue generation for authors and
                publishers.
              </li>
              <li>
                Monetizing User Creativity (VAC): Platforms like TikTok and
                YouTube rely on revenue sharing, but eksplode!’s VAC program
                acquires IP rights for $1.00 per accepted video. This builds a
                library of adready content that can be resold to brands.
              </li>
              <li>
                Targeting Emerging Markets: While competitors prioritize Western
                markets, C5M focuses on high-growth regions like Southeast Asia
                and Latin America. Titan Mobile Gaming’s ad-supported free games
                attract users often overlooked by others.
              </li>
              <li>
                Accessible NFTs and Digital Art (Gallery Aurora): Unlike
                exclusive NFT platforms, Gallery Aurora provides free access to
                NFT galleries, with monetization through ads and optional
                premium features. This opens NFTs to casual users and creators.
              </li>
            </ul>
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            By leveraging these unique advantages, C5M positions itself as a
            leader in digital media, commerce, and entertainment, capable of
            capturing market share across multiple industries.
            <ul style={{ marginLeft: 20 }}>
              <li>
                Digital Advertising: A $650 billion market growing at 9%
                annually
              </li>
              <li>eBooks: Expected to reach $23 billion globally by 2026.</li>
              <li>Mobile Gaming: A $200 billion global market.</li>
              <li>
                Digital Art and NFTs: The digital art market, which includes
                NFTs, reached $11.8 billion in sales in 2023 and is expected to
                continue growing at a compound annual growth rate of 9.0% from
                2023 to 2030
              </li>
              <li>
                Music Streaming: Global revenue exceeded $28.6 billion in 2023.
              </li>
              <li>
                Online Marketplaces: The e-commerce market reached $8.8 trillion
                in 2024.
              </li>
            </ul>
            C5M’s multi-faceted approach taps into these high-growth markets by
            combining entertainment, content, and commerce.
          </Typography>
          <br />
          <h3>Business Model</h3>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            C5M will generate revenue through:
            <ul style={{ marginLeft: 20 }}>
              <li>
                Advertising: C5M’s platforms were developed to deploy
                revenue-generating ads.
              </li>
              <li>
                Commission: Sales commission will be made through hoisting
                transactions on C5M’s platforms.
              </li>
              <li>
                Music Streaming: Galaxie Music will earn revenue from premium
                subscriptions.
              </li>
              <li>
                eMoney Network: Users of the eMoney Network can trade their
                eMoney credits for digital Walmart gift cards at a rate of 5% of
                the value owed. C5M can purchase bulk cards at 5% off the dollar
                value, thus retaining 10% of what is owed to the eMoney
                participant.
              </li>
              <li>
                Video Ad Contest (VAC): This program monetizes user-generated
                content by acquiring intellectual property for accepted videos
                and reselling winning content to advertisers at premium rates.
              </li>
              <li>
                GreenGenie: GreenGenie will generate revenue by leveraging a
                shared investment model that reinvests participants' eMoney
                funds into C5M's growth initiatives, such as marketing campaigns
                and technological development. Participants benefit from revenue
                sharing on returns, creating a cyclical reinvestment ecosystem.
                Additionally, the platform incentivizes usage through exclusive
                discounts on ad space and promotional opportunities, enhancing
                participant visibility and engagement. This structure ensures a
                steady influx of funds into C5M, fostering scalable growth and
                sustainable participant integration while delivering an average
                projected ROI of 15% annually.
              </li>
            </ul>
          </Typography>
          <br />
          <h3>Growth Strategies</h3>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 20 }}>
              <li>
                Exposure: C5M’s platforms were developed to land users on its
                homepage, where they must scroll to access the desired
                platform(s). This system provides the following benefits:
                <ul style={{ marginLeft: 20 }}>
                  <li>
                    Cross-Platform Exposure:
                    <ul style={{ marginLeft: 20 }}>
                      <li>
                        Users are exposed to the full range of C5M’s platforms
                        (KleverBook™, Titan Mobile Gaming, Galaxie Music,
                        Gallery Aurora, etc.) as they scroll.
                      </li>
                      <li>
                        This increases awareness and drives traffic to platforms
                        they may not have known about.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Brand Visibility and Cohesion:
                    <ul style={{ marginLeft: 20 }}>
                      <li>
                        Reinforces C5M as an integrated ecosystem rather than
                        separate entities
                      </li>
                      <li>
                        Builds a stronger brand identity by showcasing multiple
                        offerings in one place.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Upsell and Engagement Opportunities:
                    <ul style={{ marginLeft: 20 }}>
                      <li>
                        Users are more likely to explore additional services
                        while navigating the homepage
                      </li>
                      <li>
                        C5M participants have personalized dashboards to improve
                        cross-platform engagement and lifetime value (LTV).
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                KleverBook™: The novelty of the KleverBook™ app and its luring
                appeal to authors/publishers and users will generate broad
                interest and steady revenue from pre-paid ads and sales
                commission. Moreover, the generous 50/50 ad-revenue split
                between C5M and the book’s author/publisher increases the
                likelihood of cooperative promotion.
              </li>
              <li>
                Titan Mobile Gaming: Early testing shows strong engagement
                potential with ad-supported gameplay and rewards for top
                players. The platform is uniquely positioned to capitalize on
                the $200 billion mobile gaming industry.
              </li>
              <li>
                Galaxie Music: Will grow a catalog of ad-supported and premium
                streaming content, contributing to the $28.6 billion music
                streaming market.
              </li>
              <li>
                Gallery Aurora: Gallery Aurora is dedicated to providing a free
                space for artists to display and sell their works in NFT format.
                This specialized focus on NFTs and their integration within the
                broader C5M ecosystem makes it distinctive compared to general
                online art galleries. Moreover, through d2p and the KleverBook™
                app, authors/publishers can post pictures on GA’s platform that
                can be accessed via QR Codes printed in books. The benefits of
                this system are enormous:
                <ul style={{ marginLeft: 20 }}>
                  <li>
                    Cost Savings: Writers can include high-quality images in
                    their books or associated content without the additional
                    cost of printing them in physical copies. This reduces
                    production expenses, especially for images that may be
                    expensive to print.
                  </li>
                  <li>
                    Enhanced Reader Experience: By directing readers to GA
                    through a QR code, the writer can offer an enriched
                    experience with visual content, giving readers access to
                    supplementary material that enhances the book's narrative or
                    theme
                  </li>
                  <li>
                    Increased Exposure: Posting pictures on GA allows the
                    author's work to be showcased in a dedicated digital art
                    space, increasing visibility among a broader audience. Since
                    GA is part of the C5M ecosystem, the exposure can lead to
                    more connections with other C5M platforms and projects,
                    expanding the writer’s reach.
                  </li>
                  <li>
                    No Additional Costs: Since there are no extra fees for
                    including images in a digital upload, writers can maximize
                    the value of their work without worrying about hidden costs.
                    This makes GA a cost-effective solution for hosting digital
                    images alongside books.
                  </li>
                  <li>
                    Flexibility and Ease of Updates: Writers can easily update,
                    remove, or add pictures to their book's supplementary
                    content without incurring additional fees or the hassle of
                    reprinting physical copies or revising digital books sold on
                    platforms like Amazon or Kindle. This flexibility allows
                    them to keep their content fresh and make quick adjustments
                    without the complex and costly process of republishing or
                    printing new editions.
                  </li>
                  <li>
                    Integration with NFTs and Digital Art: As GA supports NFTs,
                    writers can even explore the possibility of minting and
                    selling digital versions of their images as NFTs, offering
                    another avenue for monetization
                  </li>
                  <li>
                    Future-Proof Content: By leveraging a platform like GA, the
                    writer can ensure that their visual content remains
                    accessible and relevant in the growing digital and NFT
                    markets, keeping the content up-to-date with emerging
                    trends.
                  </li>
                </ul>
              </li>
              <li>
                eMoney Network: This unique digital payment solution, which does
                not require links to external accounts or parental supervision
                and provides seamless transactions within C5M's ecosystem, will
                undoubtedly grow in popularity. Its additional benefits include
                allowing users to share funds within the network or trade funds
                for Walmart gift cards.
              </li>
              <li>
                DAC Program: Earning cash rewards through social media will
                drive p2p user engagement.
              </li>
              <li>
                Video Ad Contest (VAC): Unlike TikTok Challenges and YouTube
                Video Contest, the VAC stands out by combining the competitive,
                reward-based format with a focus on amateur video creators and
                structured, interactive brand promotion. It offers a more
                formalized contest system where participants can earn money or
                prizes, making it a unique way for brands to engage with
                creators. This approach will incentivize brands to participate
                and, in turn, lure more participants.
              </li>
              <li>
                Retailer Commissions: Based on the uniqueness of C5M’s platforms
                and ecosystem, retailers and brands will seek to market
                themselves with us.
              </li>
              <li>
                Impact Records: As of October 2024, there are approximately 21.7
                million registered music artists worldwide, encompassing solo
                artists, bands, and groups across various genres. Most of these
                artists actively create and distribute their music, often
                online. The rise of digital distribution has democratized music
                sales, enabling artists to reach global audiences without
                traditional intermediaries. Here’s how IR differs from these
                distribution services:
                <ul style={{ marginLeft: 20 }}>
                  <li>
                    Holistic Media Ecosystem: C5M offers more than just music
                    distribution. It integrates various media and entertainment
                    elements, including publishing, streaming services, gaming,
                    digital art, and interactivity. This broader ecosystem
                    allows for multiple forms of engagement and monetization
                    beyond just selling music.
                  </li>
                  <li>
                    Revenue Sharing and Interactive Features: Platforms of C5M
                    emphasize creative interaction and reward-based
                    participation. For example, eksplode! integrates interactive
                    and gamified advertising features, which isn't typically a
                    core focus of music distribution platforms like DistroKid or
                    CD Baby.
                  </li>
                  <li>
                    . Monetization Beyond Sales: While platforms like CD Baby
                    and TuneCore focus on music sales and streaming royalties,
                    C5M also incorporates revenue-sharing models through
                    advertisements and interactive challenges, providing diverse
                    ways for musicians to earn beyond just selling tracks.
                  </li>
                  <li>
                    VAC Exposure: Artists participating in the Galaxie Music
                    Library gain worldwide exposure by having their music used
                    in the VAC program.
                  </li>
                  <li>
                    NFT Integration: Gallery Aurora also hosts NFTs, a feature
                    not typically in traditional music distribution platforms.
                    This allows artists to create digital collectibles tied to
                    their music.
                  </li>
                </ul>
              </li>
            </ul>
            In summary, C5M is unique in that it offers a multi-faceted platform
            for artists to engage with various media forms (not just music), and
            its monetization models go beyond traditional sales and royalties.
            This makes it a more comprehensive and integrated platform than a
            conventional music distribution service.
          </Typography>
          <br />
          <h3>
            Competitive Edge <br /> Concrete Examples of Differentiation
          </h3>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 20 }}>
              <li>
                KleverBook™ vs. Kindle: KleverBook™ offers ad-supported free
                access to digital books, ensuring author revenue and reader
                satisfaction, unlike Kindle’s subscription-only model.
              </li>
              <li>
                eMoney Network vs. Competitors: Unlike platforms like PayPal,
                Venmo, or Cash App, which require linked bank accounts or
                parental oversight for younger users, the eMoney Network
                eliminates these barriers. It provides a secure, seamless
                ecosystem for earning, spending, and transferring funds within
                the C5M platforms. Users can transact without traditional
                financial infrastructure using QR codes linked directly to C5M
                accounts. This accessibility and integration into C5M’s
                ecosystem distinguish it from competitors, fostering loyalty
                while enabling underbanked users to participate in digital
                commerce
              </li>
              <li>
                Titan Mobile Gaming vs. Competitors: Based on market research
                and insights from comparable platforms, Titan Mobile Gaming's
                ad-supported gameplay is projected to drive 20% more engagement
                time than traditional platforms. Focus group feedback and
                internal simulations further support this projection.
              </li>
              <li>
                eksplode! VAC Program: The VAC program focuses on amateur
                creators and offers a structured system for generating
                high-quality, brand-ready advertising content. Unlike platforms
                like TikTok or YouTube that rely on shared ad revenues, VAC
                allows C5M to secure intellectual property rights for $1 per
                submission, building a library of exclusive content that can be
                resold to advertisers at premium rates. This approach enhances
                creativity, rewards participants, and ensures a sustainable
                revenue model for C5M while offering advertisers proven
                audience-tested material.
              </li>
              <li>
                eksplode!’s DAC Program: The DAC program empowers users by
                enabling them to earn commissions through peer-to-peer promotion
                of retailer products. Users drive direct sales by selecting
                items from a curated catalog, creating customized Digital Ad
                Cards (DACs), and sharing these with their networks. The unique
                eksplode! Code or QR functionality ensures accurate tracking and
                commissions, making the DAC program both a user-driven revenue
                stream and a high-impact brand advertising tool. This model
                prioritizes engagement, personalization, and measurable ROI,
                differentiating it from traditional affiliate marketing
                programs.
              </li>
              <li>
                Gallery Aurora: GA hosts digital art and NFTs, providing a free
                and accessible space for artists to showcase and monetize their
                work. Unlike exclusive NFT platforms, Gallery Aurora 10
                integrates seamlessly into the C5M ecosystem, using ad revenue
                and optional premium features to ensure accessibility. Its
                cross-platform synergies, such as embedding QR codes in
                KleverBook™ or offering NFT creation tools, drive traffic and
                monetization opportunities, giving artists broader exposure
                while attracting casual users to the digital art and NFT
                markets.
              </li>
              <li>
                GreenGenie: GreenGenie adds significant value to C5M by
                fostering a closed-loop economy that enhances user loyalty,
                increases retained earnings, and reduces costs. This
                integration, combined with C5M’s diverse and scalable platforms,
                positions the company for accelerated growth, maximizes
                profitability and creates a desirable exit strategy for
                investors.
              </li>
            </ul>
          </Typography>
          <br />
          <h3>
            Market Share Goals <br /> Alignment with Market Penetration Rates
          </h3>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 20 }}>
              <li>
                Digital Advertising (KleverBook™ Ads and Titan Mobile Gaming
                Ads): Capturing 0.01% of the $650 billion global digital
                advertising market by Year 3 aligns with early-stage growth
                achieved by innovative ad platforms. KleverBook™ drives revenue
                through non-intrusive inbook advertising, appealing to readers
                while ensuring visibility for advertisers. Titan Mobile Gaming
                integrates ads into its gameplay, combining engagement with
                monetization opportunities, offering brands access to highly
                interactive audiences.
              </li>
              <li>
                Brand Engagement (eksplode! VAC and DAC): Capturing 0.01% of the
                $37 billion combined market for affiliate marketing ($17
                billion) and branded content resale ($20 billion) by Year 3
                aligns with penetration rates achieved by platforms leveraging
                user-generated content. Revenue is driven by retailer
                commissions (DAC) and premium content sales to brands (VAC),
                emphasizing performance-based and content-driven models
              </li>
              <li>
                Mobile Gaming (Titan Mobile Gaming): Aiming for 0.05% of the
                $200 billion global market by Year 3 mirrors the growth
                trajectory of ad-supported platforms like Roblox in emerging
                markets.
              </li>
              <li>
                Music Streaming (Galaxie Music): Targeting 0.01% of the $28.6
                billion global market by Year 3 through ad-supported and premium
                subscription models.
              </li>
              <li>
                Digital Publishing (KleverBook™): Aiming to secure 0.1% of the
                $23 billion eBook market by Year 3 by leveraging ad-supported
                free content and seamless revenue-sharing with publishers.
              </li>
              <li>
                Digital Art and NFTs (Gallery Aurora): Capturing 0.02% of the
                $24 billion market by Year 3 through accessible NFT creation
                tools and ad-supported free galleries.
              </li>
              <li>
                Digital Payment Commissions (eMoney Network): Capturing 0.001%
                of the $8.8 trillion ecommerce market by Year 3 through
                commissions earned on purchases made using the eMoney Network.
                This approach facilitates seamless transactions for the
                underbanked while monetizing the broader e-commerce market.
              </li>
            </ul>
          </Typography>
          <br />
          <h3>Projected Returns for Investors</h3>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            Based on the targeted market penetration rates, the projected Year 3
            revenues for each division are as follows:
          </Typography>
          <br />
          <h3>Projected Year 3 Revenues by Division</h3>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 20 }}>
              <li>
                Digital Advertising (KleverBook, TMG, GA, IR, GM and d2p):
                $65,000,000
              </li>
              <li>Brand Engagement (eksplode!’s VAC and DAC): $3,700,000</li>
              <li>Mobile Gaming (Titan Mobile Gaming): $100,000,000</li>
              <li>Music Streaming (Galaxie Music): $2,860,000</li>
              <li>Digital Publishing (KleverBook): $23,000,000</li>
              <li>Digital Art and NFTs (Gallery Aurora): $4,800,000</li>
              <li>
                Digital Payment Commissions (eMoney Network & GreenGenie):
                $100,000,000
              </li>
            </ul>
            Total Projected Year 3 Revenue: $313,560,000
          </Typography>
          <br />
          <h3>Projected Year 6 Revenues by Division</h3>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 20 }}>
              <li>Digital Advertising: $120,000,000</li>
              <li>Brand Engagement: $12,000,000</li>
              <li>Mobile Gaming: $175,000,000</li>
              <li>Music Streaming: $8,500,000</li>
              <li>Digital Publishing: $40,000,000</li>
              <li>Digital Art and NFTs: $15,000,000</li>
              <li>Digital Payment Commissions: $250,000,000</li>
            </ul>
            Total Projected Year 6 Revenue: $620,500,000
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            These projections showcase significant growth potential across C5M's
            divisions, highlighting the diversified revenue streams and market
            opportunities. Let me know if you’d like to refine this further or
            calculate additional metrics like profit margins or RO. However, to
            account for potential variability, we have applied a 25% error
            margin to the projections, resulting in the following adjusted
            figures:
          </Typography>
          <br />
          <h3>Year 3 Adjusted Projections:</h3>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 20 }}>
              <li>
                Digital Advertising (KleverBook, TMG, GA, IR, GM and d2p):
                $48,750,000
              </li>
              <li>Brand Engagement (eksplode!’s VAC and DAC): $2,775,000</li>
              <li>Mobile Gaming (Titan Mobile Gaming): $75,000,000</li>
              <li>Music Streaming (Galaxie Music): $2,145,000</li>
              <li>Digital Publishing (KleverBook): $17,250,000</li>
              <li>Digital Art and NFTs (Gallery Aurora): $3,600,000</li>
              <li>
                Digital Payment Commissions (eMoney Network & GreenGenie):
                $75,000,000
              </li>
            </ul>
            Total Adjusted Year 3 Revenue: $224,520,000
          </Typography>
          <br />
          <h3>Year 6 Adjusted Projections:</h3>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 20 }}>
              <li>Digital Advertising: $90,000,000</li>
              <li>Brand Engagement: $9,000,000</li>
              <li>Mobile Gaming: $131,250,000</li>
              <li>Music Streaming: $6,375,000</li>
              <li>Digital Publishing: $30,000,000</li>
              <li>Digital Art and NFTs: $11,250,000</li>
              <li>Digital Payment Commissions: $187,500,000</li>
            </ul>
            Total Adjusted Year 6 Revenue: $465,375,000
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            These adjusted figures provide a conservative outlook, acknowledging
            a margin for risk while maintaining confidence in C5M’s diversified
            revenue streams' growth potential.
          </Typography>
          <br />
          <h3>
            Proposed Dividend Policy for C5M <br /> Objective
          </h3>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            To provide consistent and attractive returns to investors while
            retaining sufficient earnings for growth and reinvestment in C5M's
            diverse platform ecosystem.
          </Typography>
          <br />
          <h3>Dividend Payout Ratio</h3>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            C5M, as a growth-oriented company, has adopted a progressive
            dividend policy that balances investor expectations with
            reinvestment needs:
            <ul style={{ marginLeft: 20 }}>
              <li>
                Initial Payout (Years 1–3):
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Allocate 10% of net revenue for dividends to reward early
                    investors without constraining growth.
                  </li>
                  <li>
                    This ensures funds are prioritized for scaling operations,
                    platform development, and marketing initiatives.
                  </li>
                </ul>
              </li>
              <li>
                Mid-Stage Payout (Years 4–6):
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Increase to 20% of net revenue, reflecting enhanced
                    profitability as platforms stabilize and achieve market
                    penetration.
                  </li>
                  <li>
                    Dividends will scale in proportion to revenue growth and
                    sustained profitability.
                  </li>
                </ul>
              </li>
              <li>
                Mature Stage Payout (Years 7+):
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Transition to a payout ratio of 30–40% of net revenue,
                    similar to mature companies, once C5M achieves steady cash
                    flows and operational efficiencies.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <br />
          <h3>Projected Returns</h3>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Year 3 Example:</b>
            <ul style={{ marginLeft: 20 }}>
              <li>Projected Year 3 Revenue: $215,520,000</li>
              <li>Dividend Allocation (10%): $21,552,000</li>
              <li>
                Investor with $10,000 Investment (~0.035% Equity):
                <ul style={{ marginLeft: 40 }}>
                  <li>Dividend Share: $7,543.20</li>
                  <li>Annual Dividend (Over 3 Years): ~$2,514.40 per year</li>
                </ul>
              </li>
            </ul>
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Example Investments of $10,000 in Year 3:</b>
            <ul style={{ marginLeft: 20 }}>
              <li>
                If reinvested in marketing campaigns, the $10,000 could generate
                a projected ROI of 15%, returning $11,500 within a year
              </li>
              <li>
                Allocating $10,000 to platform enhancements could increase
                platform user acquisition rates, boosting overall revenue and
                dividend returns.
              </li>
            </ul>
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Years 4–6 Example:</b>
            <ul style={{ marginLeft: 20 }}>
              <li>
                Projected Year 6 Revenue: $375,000,000
                <ul style={{ marginLeft: 40 }}>
                  <li>Dividend Allocation (20%): $75,000,000</li>
                  <li>Investor Share: $26,250</li>
                </ul>
              </li>
            </ul>
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Example Investments of $10,000 in Years 4–6:</b>
            <ul style={{ marginLeft: 20 }}>
              <li>
                Investing in digital advertising tools during this period could
                yield a 20% return, totaling $12,000.
              </li>
              <li>
                Supporting the development of mobile gaming features with
                $10,000 might drive significant engagement, indirectly enhancing
                dividend earnings.
              </li>
            </ul>
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Additional Considerations</b>
            <ul style={{ marginLeft: 20 }}>
              <li>
                Reinvestment Priority: Retain most earnings to fuel growth in
                high-potential divisions like Titan Mobile Gaming, Galaxie
                Music, and eksplode!.
              </li>
              <li>
                Flexibility: C5M should retain the option to adjust dividends
                based on market conditions, revenue fluctuations, and
                reinvestment opportunities.
              </li>
              <li>
                Incentives for Investors: Offer dividend reinvestment plans
                (DRIPs) to encourage investors to reinvest dividends into
                additional shares, promoting long-term engagement.
              </li>
            </ul>
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            This dividend policy ensures that C5M balances investor returns with
            the financial flexibility needed to achieve its ambitious growth
            objectives.
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Ownership and Investment Opportunity:</b>
            <ul style={{ marginLeft: 20 }}>
              <li>Total Shares Issued: 10,000,000 common shares.</li>
              <li>
                Shares for Sale: 500,000 shares at $1.00 each, representing 5%
                of the company’s total ownership.
              </li>
            </ul>
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Funding Allocation (Based on $500,000)</b>
            <ul style={{ marginLeft: 20 }}>
              <li>
                Platform Development: $125,000 (25%)
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Advance the core technologies across all C5M divisions,
                    including KleverBook™, Titan Mobile Gaming, Galaxie Music,
                    Gallery Aurora, and the eMoney Network.
                  </li>
                  <li>
                    Enhance eksplode!’s DAC program to improve scalability and
                    introduce advanced user features
                  </li>
                  <li>
                    Enhance eksplode!’s DAC program to improve scalability and
                    introduce advanced user features
                  </li>
                </ul>
              </li>
              <li>
                Marketing and Growth: $175,000 (35%)
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Execute comprehensive campaigns to build brand awareness
                    across all C5M divisions.
                  </li>
                  <li>
                    Promote Galaxie Music’s streaming services and Gallery
                    Aurora’s NFT offerings to key demographics.
                  </li>
                  <li>
                    Drive adoption of Titan Mobile Gaming with competitive
                    tournaments and interactive social engagement.
                  </li>
                </ul>
              </li>
              <li>
                Operations and Staffing: $125,000 (25%)
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Recruit key personnel to manage operations and growth
                    initiatives across divisions.
                  </li>
                  <li>
                    Build support teams for customer engagement and content
                    moderation, ensuring seamless user experience.
                  </li>
                </ul>
              </li>
              <li>
                Miscellaneous/Buffer: $75,000 (15%)
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Reserve funds for unforeseen expenses, emerging
                    opportunities, and further optimization of C5M’s ecosystem.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Strategies</b>
            <ul style={{ marginLeft: 40 }}>
              <li>
                Ad Personalization with User Engagement:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Leverage eksplode!'s VAC and DAC programs to offer brands
                    measurable ROI through user-generated content and
                    click-through purchases.
                  </li>
                  <li>
                    Highlight the conversion power of C5M’s innovative,
                    engagement-first ad models.
                  </li>
                </ul>
              </li>
              <li>
                Partnerships with Agencies and Brands:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Partner with digital marketing agencies to introduce
                    eksplode! as an add-on service for their clients.
                  </li>
                  <li>
                    Target small to medium-sized businesses (SMBs) initially,
                    offering cost-effective advertising solutions.
                  </li>
                </ul>
              </li>
              <li>
                Gamified Ad Campaigns:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Use Titan Mobile Gaming’s advergaming model (GAC) to
                    integrate brands seamlessly into gameplay, boosting user
                    interaction.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <br />
          <h3> eBook’s ($23 Billion Market)</h3>
          <br />
          <p>
            <b>Goal:</b> Capture 0.1% market share by Year 3
          </p>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Strategies:</b>
            <ul style={{ marginLeft: 40 }}>
              <li>
                Partner with Authors and Publishers:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Collaborate with independent authors and small publishers to
                    showcase KleverBook™ as a low-risk monetization platform.
                  </li>
                  <li>
                    Offer ad revenue-sharing agreements to incentivize early
                    adoption.
                  </li>
                </ul>
              </li>
              <li>
                Leverage Free Content to Build an Audience:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Provide free access to popular eBooks with integrated
                    advertising to attract large-scale readership.
                  </li>
                  <li>
                    Market KleverBook™ as an accessible publishing solution for
                    underrepresented authors.
                  </li>
                </ul>
              </li>
              <li>
                Ad-Native Content Creation:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Encourage publishers to create eBooks that integrate
                    seamlessly with non-disruptive ads, ensuring better reader
                    engagement.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <br />
          <h3>Mobile Gaming ($200 Billion Market)</h3>
          <br />
          <p>
            <b>Goal:</b>Capture 0.05% market share by Year 3.
          </p>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Strategies:</b>
            <ul style={{ marginLeft: 40 }}>
              <li>
                Host Competitive Tournaments:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Offer cash rewards for high scores in Titan Mobile Gaming,
                    driving virality and adoption among gaming communities.
                  </li>
                  <li>
                    Collaborate with influencers and esports leagues to promote
                    tournaments.
                  </li>
                </ul>
              </li>
              <li>
                In-Game Rewards and Microtransactions:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Integrate purchasable in-game rewards, such as skins or
                    upgrades, while offering adsupported free options.
                  </li>
                  <li>
                    Use reward-driven gameplay to build loyalty and engagement
                  </li>
                </ul>
              </li>
              <li>
                Target Emerging Markets:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Focus on markets where mobile gaming adoption is booming
                    (e.g., India and Southeast Asia), emphasizing ad-supported
                    free games.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <br />
          <h3>Music Streaming ($28.6 Billion Market)</h3>
          <br />
          <p>
            <b>Goal:</b>Capture 0.01% market share by Year 3.
          </p>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Strategies:</b>
            <ul style={{ marginLeft: 40 }}>
              <li>
                Promote Galaxie Music’s Unique Features:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Highlight the ad-supported and premium subscription options
                    to cater to a diverse audience.
                  </li>
                  <li>
                    Leverage the Galaxie Music Library (GML) for royalty-free
                    content to attract creators and advertisers.
                  </li>
                </ul>
              </li>
              <li>
                Collaboration with Emerging Artists:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Partner with indie musicians to distribute their music,
                    providing exposure while offering affordable content to
                    users.
                  </li>
                </ul>
              </li>
              <li>
                Create Exclusive Sponsored Playlists:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Develop curated playlists sponsored by brands, boosting ad
                    revenue while engaging listeners.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <br />
          <h3>Digital Art and NFTs ($24 Billion Market)</h3>
          <br />
          <p>
            <b>Goal:</b>Capture 0.02% market share by Year 3.
          </p>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Strategies:</b>
            <ul style={{ marginLeft: 40 }}>
              <li>
                Onboard Creators and NFT Enthusiasts:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Offer low-fee or free onboarding for creators, incentivizing
                    them to showcase their work on Gallery Aurora.
                  </li>
                  <li>
                    Provide tools for NFT creation and ad placement
                    monetization.
                  </li>
                </ul>
              </li>
              <li>
                Leverage Free Access to Draw Users:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Offer free access to particular galleries to attract a broad
                    audience while selling premium features.
                  </li>
                </ul>
              </li>
              <li>
                Collaborate with Advertisers:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Use ad placements within NFT galleries to monetize free
                    users and provide brands with innovative exposure options.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <br />
          <h3>Online Marketplaces ($8.8 Trillion Market)</h3>
          <br />
          <p>
            <b>Goal:</b>Capture 0.001% market share by Year 3.
          </p>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Strategies:</b>
            <ul style={{ marginLeft: 40 }}>
              <li>
                Enhance Retailer Partnerships:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Partner with small-to-medium retailers to integrate their
                    catalogs into C5M’s platforms via eksplode! And DAC.
                  </li>
                  <li>
                    Provide performance-based commission incentives (5%-10%).
                  </li>
                </ul>
              </li>
              <li>
                Integrate eMoney Network for Seamless Payments:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Market the eMoney Network to underbanked users and showcase
                    its ease of use for online transactions within the C5M
                    ecosystem
                  </li>
                </ul>
              </li>
              <li>
                Incentivized Transactions Through Cashback and Rewards:
                <ul style={{ marginLeft: 40 }}>
                  <li>
                    Offer cashback or points on purchases made through
                    DAC-driven marketplace links to encourage user retention.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <br />
          <h3>Exit Strategy</h3>
          <br />
          <p>
            C5M offers multiple avenues for investors to realize significant
            returns on their investments:
          </p>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>Strategies:</b>
            <ul style={{ marginLeft: 40 }}>
              <li>
                Strategic Acquisition: C5M's platform ecosystem is designed to
                appeal to major technology and media conglomerates such as
                Facebook (Meta), Google (Alphabet), or Amazon. These companies
                could leverage C5M's unique capabilities in advergaming, NFT
                hosting, and innovative payment solutions to enhance their
                ecosystems. An acquisition could occur within 5–7 years as C5M
                establishes a substantial market presence, with an estimated
                $1–2 billion valuation.
              </li>
              <li>
                Initial Public Offering (IPO): With diversified revenue streams
                and high-growth potential, C5M can transition to a public
                offering within 5–6 years. This would provide liquidity for
                investors while positioning the company for further expansion in
                global markets.
              </li>
              <li>
                Revenue Buybacks: Once cash flows stabilize, C5M may offer to
                buy back shares at a premium, rewarding early investors while
                maintaining equity control.
              </li>
              <li>
                Partnership or Licensing: C5M can pursue partnerships or
                licensing deals with leading firms, ensuring long-term revenue
                sharing while preserving operational independence. This hybrid
                model could serve as a stepping stone to acquisition or IPO.
              </li>
            </ul>
          </Typography>
          <br />
          <h3>Cross-Platform Synergies</h3>
          <br />
          <p>
            <b>
              Leverage C5M’s Ecosystem to Boost Adoption Across All Platforms:
            </b>
          </p>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 40 }}>
              <li>
                Use the eMoney Network to tie all platforms together, rewarding
                users for activities across C5M.
              </li>
              <li>
                Offer exclusive perks (e.g., discounts or premium features) for
                users engaging across multiple platforms (e.g., reading on
                KleverBook™, gaming on Titan, and buying NFTs on Gallery Aurora)
              </li>
            </ul>
          </Typography>
          <br />
          <h3>Key Metrics to Track for Success</h3>
          <br />

          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 40 }}>
              <li>
                User Acquisition Costs (UAC): Monitor the cost of gaining new
                users to ensure profitability
              </li>
              <li>
                Customer Lifetime Value (CLV): Track how much revenue a user
                generates across C5M platforms.
              </li>
              <li>
                Conversion Rates: Measure the percentage of ad viewers or
                participants who complete the desired action (e.g., purchases or
                sign-ups).
              </li>
              <li>
                Monthly Active Users (MAU): Assess engagement and retention
                across all platforms.
              </li>
            </ul>
          </Typography>
          <br />
          <p>
            By focusing on these strategies, C5M can effectively differentiate
            itself and scale its market share in each target industry.
          </p>
          <br />
          <h3>Leadership Profile</h3>
          <br />

          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            C5M’s leadership is a testament to resilience and transformation. In
            2002, our CEO faced a federal drug trafficking conviction. During
            his incarceration, he utilized the time to study law, business, and
            marketing, which became the catalyst for profound personal and
            professional growth. Over the years, he has rebuilt his life and
            career, focusing on ethical leadership, creativity, and innovation.
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            This transformative journey has directly shaped C5M’s core values:
            integrity, accountability, and opportunity creation. The CEO’s
            unwavering commitment to these values is reflected in his personal
            investment of nearly $400,000, funded through an inheritance, to
            build C5M and its platforms over the past four years.
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            By sharing this history openly, we demonstrate our dedication to
            transparency and ethical business practices, ensuring investors and
            partners understand the foundation of our mission and vision.
          </Typography>
          <br />
          <h3>Contact Information:</h3>
          <p>Admin@C5M.world</p>
          <br />
          <a
            href="https://c5m-assets.s3.amazonaws.com/1739222609499.pdf"
            download
          >
            <b>Download [Investment Pitch] (Extended Version)PDF</b>
          </a>
        </Box>
        <Box mb={2}>
          <Grid container justify="center" alignItems="center" spacing={-2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.list}>
                <ListItem>
                  <a href="http://galaxie.ir.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/ir.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://d2p.web.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/d2p_3.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.list}>
                <ListItem>
                  <a>
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/galaxie.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://titan.gaming.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/titanlogo.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://gallery.aurora.web.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/gallerylgo.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <List className={classes.list}>
                <ListItem>
                  <a href="http://galaxie.gr.s3-website-us-east-1.amazonaws.com">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.galaxy}
                        src="images/galaxy.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a>
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo2 && classes.eMoney}
                        src="images/eMoney.jpe"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://eksplode.web.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo2 && classes.eks}
                        src="images/eksplode.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="/greengenie/comingsoon">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo2 && classes.green}
                        src="images/greengenie.jpe"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
              </List>
            </Grid>
          </Grid>

          <ListItem>
            <ListItemText>
              <a href="/">
                <Box textAlign="center" fontWeight="bold">
                  HOME
                </Box>
              </a>
            </ListItemText>
          </ListItem>
        </Box>
      </Container>
    </div>
  );
};

export default LongInvestPitch;
