import axios from "axios";

const backendD2P = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? "http://localhost:9000"
      : "http://ec2-3-80-110-152.compute-1.amazonaws.com",
});

backendD2P.interceptors.request.use(
  (config) => {
    let token = "";
    token = sessionStorage.getItem("token");
    if (!token) token = localStorage.getItem("token");
    if (token) config.headers = { Authorization: `Bearer ${token}` };
    return config;
  },
  (error) => Promise.reject(error)
);

export default backendD2P;
