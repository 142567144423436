/*  eslint-disable */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

import { Topbar, Footer } from "./components";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 56,
		height: "100%",
		[theme.breakpoints.up("sm")]: {
			paddingTop: 64,
		},
	},
	shiftContent: {
		paddingLeft: 240,
	},
	content: {
		height: "100%",
	},
}));

const Minimal = (props) => {
	const { children } = props;

	const classes = useStyles();

	return (
		<Fragment>
			<Topbar />
			<main className={classes.content}>{children}</main>
			<Footer />
		</Fragment>
	);
};

Minimal.propTypes = {
	children: PropTypes.node,
};

export default Minimal;
