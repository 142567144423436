/*  eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
} from "@material-ui/icons";
import {
  makeStyles,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Button
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(4),
    backgroundColor: theme.palette.background.darker,
    color: theme.palette.white,
  },
  footer_bottom: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.dark,
    textAlign: "center",
  },
  list: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.white,
  },
  logo: {
    width: "200px",
    height: "auto",
    objectFit: "cover",
  },
  icon: {
    color: theme.palette.white,
  },
  navbtn: {
    padding: "10px",
    margin: "0 1rem",
    color : theme.palette.white,
    backgroundColor: "#424242"
    },
}));

const Footer = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <footer>
      <div {...rest} className={clsx(classes.root, className)}>
        <Box
          width={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box my={2}>
            <img className={classes.logo} src="images/c5m-light.png" alt="" />
          </Box>
          <Box mb={2}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <List className={classes.list}>
                  <ListItem>
                    <ListItemText>
                      <a href="#" target="_blank">
                        <Box textAlign="center" fontWeight="bold">
                          MUSIC
                        </Box>
                      </a>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <a href="http://54.172.169.122/" target="_blank">
                        <Box textAlign="center" fontWeight="bold">
                          PUBLISHING
                        </Box>
                      </a>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <List className={classes.list}>
                  <ListItem>
                    <ListItemText>
                      <a href="#" target="_blank">
                        <Box textAlign="center" fontWeight="bold">
                          MOVIE/VIDEO
                        </Box>
                      </a>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <a
                        href="http://eksplode-site.s3-website.ap-south-1.amazonaws.com"
                        target="_blank"
                      >
                        <Box textAlign="center" fontWeight="bold">
                          MARKETING
                        </Box>
                      </a>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={8} xl={6}>
                <List className={classes.list}>
                <ListItem>
                    <ListItemText>
                      <a href="#" target="_blank">
                        <Box textAlign="center" fontWeight="bold">
                          INTERACTIVE
                        </Box>
                      </a>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <a href="#" target="_blank">
                        <Box textAlign="center" fontWeight="bold">
                          MOBILE GAMING
                        </Box>
                      </a>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <a
                        href="http://eksplode-site.s3-website.ap-south-1.amazonaws.com"
                        target="_blank"
                      >
                        <Box textAlign="center" fontWeight="bold">
                          DIGITAL ART
                        </Box>
                      </a>
                    </ListItemText>
                  </ListItem>

                  <ListItem>
                    <ListItemText>
                      <a
                        href="http://eksplode-site.s3-website.ap-south-1.amazonaws.com"
                        target="_blank"
                      >
                        <Box textAlign="center" fontWeight="bold">
                          STREAMING SERVICE
                        </Box>
                      </a>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <List className={classes.list}>
              <ListItem>
                <ListItemIcon>
                  <Box>
                    <IconButton className={classes.icon}>
                      <FacebookIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Box>
                    <IconButton className={classes.icon}>
                      <TwitterIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Box>
                    <IconButton className={classes.icon}>
                      <InstagramIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </ListItemIcon>
              </ListItem>
            </List>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button
              className={classes.navbtn}
              variant="contained"
              // onClick={}
            >
              Privacy Policy
            </Button>
            <Button
               className={classes.navbtn}
              variant="contained"
              // onClick={}
            >
              Terms Of Use
            </Button>
            </Box>
        </Box>
        <div className={classes.footer_bottom}>
          <Typography color="inherit" variant="body1">
            &copy; C5M - CONTINENT 5 MEDIA 2022
          </Typography>
          <Typography variant="caption">
            Your one-stop site for entertainment, rewards and online shoppping.
          </Typography>
        </div>
        
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
