import React from "react";
import {
  makeStyles,
  Typography,
  Container,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { Link } from "react-router-dom";
// import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.white,
    position: "relative",
    height: "100vh",
  },
  aboutSection: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  logo1: {
    width: "40%",
    height: "auto",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    // marginLeft: "25%",
    textAlign: "center",
  },
  picBox: {
    // width: "40%",
    // height: "auto",
    // objectFit: "cover",
    // [theme.breakpoints.down("xs")]: {
    //     width: "60%",
    // },
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    // marginLeft: "25%",
    textAlign: "center",
  },
  list: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    // alignItems: "center",
    // color: theme.palette.primary,
  },
  logo: {
    width: "160px",
    height: "auto",
    objectFit: "cover",
    marginLeft: "-55%",
    justifyContent: "center",
    alignItems: "center",
    backgorund: "transparent",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },

  logo2: {
    width: "160px",
    height: "auto",
    objectFit: "cover",
    marginLeft: "-55%",
    justifyContent: "center",
    alignItems: "center",
    backgorund: "transparent",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "80%",
    },
  },
  eMoney: {
    marginLeft: "-5px",
    width: "160px",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "95%",
    },
  },
  eks: {
    marginLeft: "30px",
    width: "160px",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "100%",
    },
  },
  green: {
    marginLeft: "60px",

    width: "160px",
    // marginTop: "-50px",
    marginBottom: "30px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "95%",
    },
  },
  galaxy: {
    width: "160px",
    height: "auto",
    objectFit: "cover",
    marginLeft: "-55%",
    justifyContent: "center",
    alignItems: "center",
    backgorund: "transparent",
    marginTop: "-50px",
    [theme.breakpoints.up("xs")]: {
      // marginLeft: "-55px",
      width: "100%",
    },
  },
}));

const ShortInvestPitch = (props) => {
  const classes = useStyles();
  return (
    <div id="ShortInvestPitch" className={classes.root}>
      <Container maxWidth="md">
        <Box mb={2} className={classes.picBox}>
          <img className={classes.logo1} src="images/c5m-dark.png" alt="" />
          <h3>C5M Investor Pitch (short version)</h3>
          <p>Last revised January 16, 2025</p>
        </Box>
        <Box textAlign="justify">
          <h4>Introduction</h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            Continent 5 Media Group (C5M) is revolutionizing digital media with
            an innovative ecosystem that bridges content creation, digital
            marketing, and consumer engagement. Our mission is to Wow the World
            by seamlessly connecting consumers and retailers through rewarding,
            interactive platforms. Powered by cutting-edge solutions like
            GreenGenie and the eMoney network, C5M is pioneering the future of
            digital innovation. It is uniquely positioned to disrupt the online
            marketplaces, publishing, music, gaming, and digital advertising
            industries.
          </Typography>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            C5M invites investors to join us in transforming how digital media
            serves creators and consumers. We are raising capital through a
            Simple Agreement for Future Equity (SAFE), a proven and transparent
            investment method that aligns investor interests with our long-term
            growth potential.
          </Typography>
          <br />
          <h4>How We Are Raising Capital: The SAFE Structure</h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            C5M is raising capital through a SAFE agreement, ensuring a
            straightforward, investor- friendly approach. SAFE agreements are
            widely trusted for their simplicity, transparency, and alignment
            with growth objectives, making them the preferred choice for
            early-stage funding. This structure allows C5M to focus on growth
            while rewarding early investors with favorable terms.
          </Typography>
          <br />
          <h4>Key Features of the SAFE Agreement:</h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 20 }}>
              <li>
                <b>No Immediate Valuation:</b> Investors receive equity in the
                company during a future financing round or liquidity event
                (e.g., acquisition or IPO) without needing an immediate company
                valuation.
              </li>
              <li>
                <b>Valuation Cap:</b>A predetermined valuation cap ensures that
                early investors benefit from a favorable conversion rate when
                equity is issued.
              </li>
              <li>
                <b>Discount Rate:</b> Early investors may receive a discount
                rate (e.g., 20%) for equity conversion, giving them an
                additional advantage.
              </li>
              <li>
                <b>Conversion to Equity:</b> Upfront funding converts into
                equity during a qualifying financing round, ensuring early
                investors benefit from C5M's growth.
              </li>
              <li>
                <b>Simplicity and Transparency:</b> SAFE agreements eliminate
                the complexity and negotiation often associated with traditional
                equity financing, expediting the process for both parties.
              </li>
            </ul>
          </Typography>
          <br />
          <h4> Why SAFE? </h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 20 }}>
              <li>
                <b>Investor-Friendly:</b> Rewards early investors with future
                equity tied to C5M's success.
              </li>
              <li>
                <b>Founder-Friendly:</b> Enables C5M to focus on growth without
                immediately setting a valuation, allowing for rapid scaling
                while ensuring investor benefits.
              </li>
              <li>
                <b>Proven Approach:</b> Widely used and trusted by successful
                startups globally.
              </li>
            </ul>
          </Typography>
          <br />
          <h4>Why Invest in C5M?</h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 20 }}>
              <li>
                <b>Disruptive Business Model:</b> C5M integrates cutting-edge
                technology and digital media solutions to create an unmatched
                consumer and creator ecosystem. Our platforms, including
                eksplode!, d2p, Gallery Aurora, Galaxie Music, and KleverBook,
                address unmet publishing, gaming, and digital advertising needs.
              </li>
              <li>
                <b>Multiple Revenue Streams:</b> C5M’s diverse revenue model
                focuses on advertising and commission-based earnings while
                maximizing efficiency and cost savings through our innovative
                eMoney Network and GreenGenie program. These strategies ensure
                sustainable growth and create significant value for users and
                businesses.
              </li>
              <li>
                <b>Scalable Vision:</b> Designed for scale, C5M is positioned to
                capture significant market share across countries and
                industries, leveraging its innovative approach to create
                long-term value.
              </li>
              <li>
                <b>Visionary Leadership:</b> Led by a dynamic, out-of-the-box
                innovator who defies convention, the CEO has personally invested
                $400,000 to build an intricate and solid infrastructure for
                explosive growth.
              </li>
            </ul>
          </Typography>
          <br />
          <h4>How Your Investment Will Drive Growth</h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            Investors participating in C5M’s SAFE offering will directly fuel
            the company’s next phase of development, including:
            <ul style={{ marginLeft: 40 }}>
              <li>
                Launching key platforms like d2p and its KleverBook program,
                eksplode! and its DAC and VAC programs, Gallery Aurora and Titan
                Mobile Gaming, to generate early revenue.
              </li>
              <li>
                Enhancing platform functionality to improve user experience and
                engagement.
              </li>
              <li>
                Expanding marketing efforts to attract both users and creators
                to the ecosystem.
              </li>
              <li>
                Supporting operational scalability to handle rapid growth and
                adoption.
              </li>
            </ul>
          </Typography>
          <br />
          <h4>Dividends: Sharing Success with Investors</h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            At C5M, we believe in rewarding our investors' trust and commitment.
            Our dividend policy ensures investors benefit directly from our
            success. After the first year of operations, C5M will distribute a
            10% annual dividend, providing an additional, steady return on
            investment alongside equity growth.
          </Typography>
          <br />
          <h4>Investor Benefits</h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <ul style={{ marginLeft: 20 }}>
              <li>
                <b>Early Participation:</b> Secure equity at the most favorable
                terms through the SAFE’s valuation cap and discount rate.
              </li>
              <li>
                <b>Growth Potential:</b> Benefit from C5M’s scalable ecosystem
                and significant upside potential.
              </li>
              <li>
                <b>Aligned Interests:</b> The SAFE ensures mutual success by
                tying investor rewards to C5M’s growth. 4. Dividends: Enjoy a
                10% annual dividend distribution after the first year of
                operations.
              </li>
            </ul>
          </Typography>
          <br />
          <h4> Closing Note</h4>
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            C5M isn’t just a company—it’s a revolution reshaping the digital
            media landscape and how online marketplaces operate. By investing in
            C5M, you join a transformative vision, empowering consumers,
            creators, and businesses to thrive in a connected, rewarding
            ecosystem. Wow the World with us, and be a part of the digital
            renaissance.
          </Typography>
          <br />
          <Typography
            color="primaryText"
            variant="body1"
            style={{ fontSize: "16px", fontFamily: "TimesNewRoman" }}
          >
            <b>If you are intrigued and want to learn more?</b> Please return to
            our <Link to={"/invest"}>Investor Page</Link>, read the long version
            of our pitch, review our SAFE contract, and explore C5M.World. If
            you have further questions, contact us at admin@C5M.World.
          </Typography>
          <br />
          <a href="https://c5m-assets.s3.amazonaws.com/1739222648456.pdf" download>
            <b>Download  [InvestmentPitch] (Short Version) PDF</b>
          </a>
        </Box>
        <Box mb={2}>
          <Grid container justify="center" alignItems="center" spacing={-2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.list}>
                <ListItem>
                  <a href="http://galaxie.ir.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/ir.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://d2p.web.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/d2p_3.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.list}>
                <ListItem>
                  <a>
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/galaxie.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://titan.gaming.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/titanlogo.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://gallery.aurora.web.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo}
                        src="images/gallerylgo.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <List className={classes.list}>
                <ListItem>
                  <a href="http://galaxie.gr.s3-website-us-east-1.amazonaws.com">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.galaxy}
                        src="images/galaxy.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a>
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo2 && classes.eMoney}
                        src="images/eMoney.jpe"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="http://eksplode.web.s3-website-us-east-1.amazonaws.com/">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo2 && classes.eks}
                        src="images/eksplode.png"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="/greengenie/comingsoon">
                    <Box textAlign="center" fontWeight="bold">
                      <img
                        className={classes.logo2 && classes.green}
                        src="images/greengenie.jpe"
                        alt=""
                      />
                    </Box>
                  </a>
                </ListItem>
              </List>
            </Grid>
          </Grid>

          <ListItem>
            <ListItemText>
              <a href="/">
                <Box textAlign="center" fontWeight="bold">
                  HOME
                </Box>
              </a>
            </ListItemText>
          </ListItem>
        </Box>
      </Container>
    </div>
  );
};

export default ShortInvestPitch;
