import React, { Fragment } from "react";
import { Welcome, Products, About, Contact } from "./components";

const Home = (props) => {
	return (
		<Fragment>
			<Welcome />
			<Products />
			{/* <About /> */}
			{/* <Contact /> */}
		</Fragment>
	);
};

export default Home;
