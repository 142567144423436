/*  eslint-disable */
import React, { useCallback } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  makeStyles,
  useScrollTrigger,
  AppBar,
  Toolbar,
  Container,
  Button,
  Box,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    backgroundColor: theme.palette.transparent,
  },
  scrolledAppbar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: `0px 3px 16px ${theme.palette.primary.dark}`,
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  navbtn: {
    minWidth: "120px",
    background: "linear-gradient(180deg, #F54748 0%, #AC090A 100%)",
    boxShadow: ": 0px 4px 12px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `10px 20px`,
    fontSize: 16,
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, window, ...rest } = props;
  const history = useHistory();

  const classes = useStyles();

  const scrollTo = useCallback((id) => {
    const el = document.querySelector(id);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return (
    <AppBar
      {...rest}
      className={clsx({
        [classes.root]: true,
        [className]: true,
        [classes.scrolledAppbar]: trigger,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <Container maxWidth="md">
          <Box display="flex" flexWrap={"wrap"} sx={{ gap: 10, py:1 }}>
            <Link to={"/aboutus"} className={classes.navbtn}>
              About Us
            </Link>
            <Link to={"/invest"} className={classes.navbtn}>
              Invest in the Digital Renaissance with C5M
            </Link>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
